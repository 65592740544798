@font-face {
	font-family: AvertaRegular;
	src: url('../assets/fonts/Intelligent Design - Averta-Regular.otf') format('opentype'),
	url('../assets/fonts/367A1E_4_0.eot');
}

@font-face {
	font-family: AvertaLight;
	src: url('../assets/fonts/Intelligent Design - Averta-Light.otf') format('opentype'),
	url('../assets/fonts/367A1E_2_0.eot');
}

@font-face {
	font-family: AvertaBold;
	src: url('../assets/fonts/Intelligent Design - Averta-Bold.otf') format('opentype'),
	url('../assets/fonts/367A1E_0_0.eot');
}

@font-face {
	font-family: AvertaItalic;
	src: url('../assets/fonts/Intelligent Design - Averta-RegularItalic.otf') format('opentype'),
	url('../assets/fonts/367A1E_5_0.eot');
}

body {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	background-color: #FFFFFF;
	box-sizing: border-box;
	color: #231F20;
	font-family: 'AvertaRegular', sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 1.8;
	margin: 0px;
	padding: 0px;
}

#app {
	width: 100%;
	height: 100%;
}
